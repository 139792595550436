








































































































import { Component, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import FormTopicModal from '@/partials/modals/Events/Topics/form.vue'
import GenericDeleteModal from '@/partials/modals/generic/delete.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

type SubmitType = 'create' | 'edit' | 'delete'

@Component({
  components: {
    TablePagination,
    Settings16,
    TrashCan16,
    Edit16,
    FormTopicModal,
    GenericDeleteModal
  }
})
export default class EventTopics extends Mixins(FlashMessageMixin, PermissionsMixin) {
  createModalIsOpened = false
  editModalIsOpened = false
  deleteModalIsOpened = false
  messageModalIsOpened = false
  filteredData = []
  topics = []

  selectedTopic = {}
  topicsTotal = 1

  errorMessage = ''

  fetchTopics (page = 1, perPage = 20) {
    // TODO: remove when API is ready
    this.filteredData = this.topics

    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(`event/${this.$route.params.id}/topic`, {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.filteredData = this.topics = camelCaseKeys(response.data.data)
        this.topicsTotal = response.data.total
      })
  }

  search (searchString: string) {
    this.filteredData = this.topics.filter((room: Record<string, any>) => {
      let { name } = room
      name = name.toLowerCase()
      searchString = searchString.toLowerCase()

      if (name.includes(searchString)) {
        return room
      }
    })
  }

  postSubmit (type: SubmitType) {
    let verb = ''
    const closeModal = {
      create: () => {
        this.createModalIsOpened = false
        verb = 'cadastrado'
      },
      edit: () => {
        this.editModalIsOpened = false
        verb = 'editado'
      },
      delete: () => {
        this.deleteModalIsOpened = false
        verb = 'removido'
      }
    }

    closeModal[type]()

    const flashMessage = {
      message: `O tema foi ${verb} com sucesso.`,
      isSuccess: true
    }

    this.setFlashMessage(flashMessage)

    this.fetchTopics()
  }

  onPageChange (pagination: Pagination) {
    this.fetchTopics(pagination.page, pagination.length)
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }
}
